import { createUseStyles } from 'react-jss';

const useStylesConference = createUseStyles({
  introSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    margin: '0 auto',
    padding: '200px 40px 20px 40px',
    boxSizing: 'border-box',
    color: 'white',
    textAlign: 'center',
    maxWidth: '1280px',
  },

  title: {
    fontFamily: 'ABCDiatypeRoundedUnlicensedTrial',
    fontSize: '32px',
    fontWeight: 'heavy',
    marginBottom: '32px',
    alignSelf: 'center',
    color: 'var(--green)',
  },

  description: {
    fontSize: '16px',
    lineHeight: '1.8',
    letterSpacing: '0.4px',
  },

  tabMenu: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: '40px 0',
    boxSizing: 'border-box',
  },

  tabMenuItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 40px',
    cursor: 'pointer',
    position: 'relative',
    color: 'white',
    '&.current': {
      color: 'var(--green)',
    },

    '& p': {
      fontFamily: 'ABCDiatypeRoundedUnlicensedTrial',
      textTransform: 'uppercase',
      fontSize: '18px',
      fontWeight: 'bold',
      letterSpacing: '0.4px',
    },
  },

  current: {
    position: 'absolute',
    top: '100%',
    left: 0,
    width: '100%',
    height: '4px',
    backgroundColor: 'var(--green)',
  },
});

export default useStylesConference;
