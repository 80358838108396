import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import useStyles from './styles';
import TheSpacer from 'components/common/spacer/thespacer';

const Loader = ({ isLoading, isError, lang }) => {
  if (isLoading) {
    return <p>{lang === 'en' ? 'Loading news...' : 'Carregando notícias...'}</p>;
  }

  if (isError) {
    return <p>{lang === 'en' ? 'Error loading news.' : 'Erro ao carregar notícias.'}</p>;
  }

  return null;
};

const NewsList = ({ data: newsList, error, isLoading }) => {
  const classes = useStyles();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const lang = location.pathname.startsWith('/pt') ? 'pt' : 'en';

  return (
    <div className={classes.container}>

      <Loader isLoading={isLoading} isError={error} lang={lang} />

      {!isLoading && !error && (
        <div className={classes.newsGrid}>
          {newsList?.length > 0 ? (
            newsList.map((newsItem, index) => (
              <Link
                to={
                  lang === 'pt'
                    ? `/pt/noticias/${newsItem.fields.slugPT}`
                    : `/news/${newsItem.fields.slug}`
                }
                key={index}
                className={`${classes.newsItem} no-underline`}
              >
                <div className={classes.row}>
                  <img
                    className={classes.image}
                    src={newsItem.fields?.Images?.[0]?.thumbnails?.large?.url}
                    alt={newsItem.fields?.Name || 'No title available'}
                  />
                  <TheSpacer size={16} horizontal />
                  <div className={classes.column}>
                    <p className={classes.date}>{newsItem.fields?.Date || 'Date unavailable'}</p>
                    <h2 className={classes.title}>
                      {lang === 'pt' ? newsItem.fields?.['Name PT'] || 'Title unavailable' : newsItem.fields?.Name}
                    </h2>
                    <p className={classes.description}>
                      {lang === 'pt'
                        ? newsItem.fields?.['Description PT'] || 'Description unavailable'
                        : newsItem.fields?.Description || 'Description unavailable'}
                    </p>
                  </div>
                </div>
              </Link>
            ))
          ) : (
            <p>
              {lang === 'en'
                ? 'No news available at the moment. Please check back later!'
                : 'Não há notícias disponíveis no momento. Por favor, volte mais tarde!'}
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export default NewsList;
