import React from 'react';
import { useLocation } from 'react-router-dom';
import useStyles from './styles.js';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { Link } from 'react-router-dom';

const fetchArtistas = async () => {
  const response = await axios.get('https://oyster-app-jiunm.ondigitalocean.app/airtable-data', {
    params: {
      table_name: 'Artistas',
    },
  });
  return response.data;
};

const ArtistasList = (props) => {
  const classes = useStyles();

  const location = useLocation();

  const lang = location.pathname.startsWith('/pt') ? 'pt' : 'en';

  const { data, error, isLoading } = useQuery({
    queryKey: ['artistas'],
    queryFn: fetchArtistas,
  });

  function capitalizeEveryWord(str) {
    return str
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  }

  function formatNameForUrl(name) {
    return name.toLowerCase().replace(/\s+/g, '-');
  }

  // Sorting the artists by name
  const sortedData = data ? [...data].sort((a, b) => a.fields.Name.localeCompare(b.fields.Name)) : [];

  return (
    <div className={classes.container}>
      <div className={classes.artistsMaxWidth}>
        <div className={classes.artistsList}>
          {isLoading && <p>Loading...</p>}
          {error && <p>Error loading data: {error.message}</p>}

          {!isLoading &&
            !error &&
            sortedData.map((artist) => (
              <Link
                to={
                  lang === 'pt'
                    ? `/pt/artista/${formatNameForUrl(artist.fields.Name)}`
                    : `/artist/${formatNameForUrl(artist.fields.Name)}`
                }
                className={classes.artists}
                key={artist.id}
              >
                <div className="col justify-center align-center">
                  {artist.fields.Images && artist.fields.Images.length > 0 && (
                    <img
                      src={artist.fields.Images[0].thumbnails.large.url}
                      className={classes.image}
                      alt={artist.fields.Name}
                      loading="lazy"
                    />
                  )}
                  <p className={classes.artistTitle}>
                    {capitalizeEveryWord(artist.fields.Name)} <span>{artist.fields.Country}</span>
                  </p>
                  {lang === 'en' ? (
                    <p className={classes.artistAbout}>
                      Selected by <span>{artist.fields['Selected by']}</span>
                    </p>
                  ) : (
                    <p className={classes.artistAbout}>
                      Selecionado por <span>{artist.fields['Selected by']}</span>
                    </p>
                  )}
                </div>
              </Link>
            ))}
        </div>
      </div>
    </div>
  );
};

export default ArtistasList;
