import React from 'react';
import { Link } from 'react-router-dom';

import useStyles from './styles.js';

import Button from 'components/button/index.js';

import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import PropTypes from 'prop-types';

const fetchArtistas = async () => {
  const response = await axios.get('https://oyster-app-jiunm.ondigitalocean.app/airtable-data', {
    params: {
      table_name: 'Artistas',
    },
  });
  return response.data;
};

const ArtistasList = ({ lang }) => {
  const classes = useStyles();
  /*   const [language] = useState(props.lang || 'en'); */

  const { data, error, isLoading } = useQuery({
    queryKey: ['artistas'],
    queryFn: fetchArtistas,
  });

  function capitalizeEveryWord(str) {
    return str
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  }

  function formatNameForUrl(name) {
    return name.toLowerCase().replace(/\s+/g, '-');
  }

  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>Error loading data: {error.message}</p>;

  return (
    <div className={classes.artistsSection}>
      {lang === 'en' && <p className={classes.artistsTitle}>Confirmed Artists</p>}
      {lang === 'pt' && <p className={classes.artistsTitle}>Artistas confirmados</p>}

      <div className={classes.maxWidth}>
        <div className={classes.artistsList}>
          {data.slice(0, 3).map((artista) => (
            <Link
              to={`/artist/${formatNameForUrl(artista.fields.Name)}`} // Using artist name in the URL
              className={classes.artists}
              key={artista.id}
            >
              <div className="col justify-center align-center">
                {artista.fields.Images && artista.fields.Images.length > 0 && (
                  <img
                    src={artista.fields.Images[0].thumbnails.large.url}
                    className={classes.image}
                    alt={artista.fields.Name}
                    loading="lazy"
                  />
                )}
                <p className={classes.artistTitle}>
                  {capitalizeEveryWord(artista.fields.Name)} <span>{artista.fields.Country}</span>
                </p>
                {lang === 'en' ? (
                  <p className={classes.artistAbout}>
                    Selected by <span>{artista.fields['Selected by']}</span>
                  </p>
                ) : (
                  <p className={classes.artistAbout}>
                    Selecionado por <span>{artista.fields['Selected by']}</span>
                  </p>
                )}
              </div>
            </Link>
          ))}
        </div>

        <Link to="/artists" className="no-underline">
          <Button background="red" color="white" fontSize="28px">
            {lang === 'en' ? 'View All' : 'Ver Todos'}
          </Button>
        </Link>
      </div>
    </div>
  );
};

ArtistasList.propTypes = {
  lang: PropTypes.string,
};

export default ArtistasList;
