import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: '240px 24px 120px 24px',
    maxWidth: '1280px',
    margin: '0 auto',
    color: 'white',
  },

  newsGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: '32px',
    width: '100%',
    '& > *:only-child': {
      gridColumn: '1 / -1',
      justifySelf: 'center',
      maxWidth: "860px"
    },
  },

  newsItem: {
    display: 'flex',
    flexDirection: 'column',
  },

  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
    width: '100%',
  },

  column: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },

  image: {
    width: '38.2%',
    height: 'auto',
    objectFit: 'cover',
    border: '2px solid var(--green)',
  },

  date: {
    fontSize: '18px',
    color: 'var(--green)',
    fontWeight: '600',
    marginBottom: '4px',
  },

  title: {
    fontFamily: 'ABCDiatypeRoundedUnlicensedTrial',
    fontSize: '18px',
    lineHeight: '24px',
    letterSpacing: '0.6px',
    marginBottom: '8px',
  },

  description: {
    fontSize: '14px',
    lineHeight: '24px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '300px',
    overflow: 'hidden',
  },

  // Add responsive styles for smaller screens
  '@media (max-width: 768px)': {
    container: {
      padding: '150px 24px 80px 24px',
    },
    newsGrid: {
      gridTemplateColumns: '1fr',
    },
    image: {
      width: '100%',
    },
    row: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    column: {
      width: '100%',
      marginTop: '16px',
    },
  },
});

export default useStyles;
