import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: '240px 24px 120px 24px',
    maxWidth: '1280px',
    margin: '0 auto',
  }
});

export default useStyles;
