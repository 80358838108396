import { createUseStyles } from 'react-jss';

const useStylesNews = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: '240px 24px 120px 24px',
    width: "100%",
    maxWidth: '1280px',
    margin: '0 auto',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    width: "100%",
    gap: "4rem"
  },
  RowSocial: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  column: {
    width: '61.8%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    gap: "2rem",
    position: "relative",
    top: "-2.5rem"
  },
  colImage: {
    width: '38.2%',
  },
  ratio: {
    paddingBottom: '100%',
    position: 'relative',
    border: '2px solid var(--green)',
  },
  image: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  selectedbyImage: {
    maxWidth: '97px',
    margin: '1rem 0',
  },
  date: {
    fontSize: '24px',
    color: 'var(--green)',
    fontWeight: '600',
    marginBottom: '8px',
  },
  title: {
    fontFamily: 'ABCDiatypeRoundedUnlicensedTrial',
    fontSize: '32px',
    lineHeight: '1em',
    letterSpacing: '1.2px',
    color: 'var(--red)',
    wordBreak: 'break-all',
  },
  description: {
    fontSize: '18px',
    lineHeight: '32px',
    color: 'var(--brown)'
  },
  selectedby: {
    fontSize: '20px',
    color: 'var(--red)',
    marginTop: '1rem',
  },

  embedPlayer: {
    width: "100%",
  },

  socials: {
    width: 'auto',
    height: '24px',
    marginRight: '16px',
    cursor: 'pointer',
  },

  embed: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    margin: '2rem 0',
    borderRadius: '20px',
    overflow: 'hidden',
  },
  '@media (max-width: 1024px)': {
    container: {
      padding: '150px 24px 80px 24px',
    },
    row: {
      display: 'flex',
      flexDirection: 'column',
    },
    column: {
      width: '100%',
      gap: "1.5rem"
    },
    colImage: {
      width: '100%',
      marginBottom: '0.5rem',
    },
  },
});

export default useStylesNews;
