import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    backgroundColor: 'white',
    padding: 'calc(300px - 6rem) 24px 80px 24px',
    minHeight: '100vh',
  },
  artistsMaxWidth: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    maxWidth: '1280px',
  },
  artistsList: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginBottom: '2rem',
  },
  artists: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '33.33%',
    padding: '3rem',
    color: 'var(--red)',
    '& img': {
      height: '250px',
      objectFit: 'contain',
    },
    '& img.big': {
      height: '4.2cm',
      padding: 0,
    },
  },
  artistsTitle: {
    fontFamily: 'ABCDiatypeRoundedUnlicensedTrial',
    fontSize: 40,
    fontWeight: 600,
    fontStyle: 'normal',
    letterSpacing: 0,
    textAlign: 'center',
    color: 'var(--red)',
    paddingBottom: '2rem',
  },
  artistTitle: {
    fontFamily: 'Switzer',
    fontSize: 32,
    fontWeight: 600,
    fontStyle: 'normal',
    letterSpacing: 0,
    textAlign: 'center',
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    padding: '1rem 0',
    '& span': {
      fontSize: '16px',
    },
  },

  artistAbout: {
    fontFamily: 'Switzer',
    fontSize: 12,
    fontWeight: 600,
    fontStyle: 'normal',
    letterSpacing: 0.4,
    textAlign: 'center',
    '& span': {
      fontSize: '16px',
    },
  },

  detailButton: {
    marginTop: '10px',
    padding: '10px 20px',
    backgroundColor: '#007BFF',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#0056b3',
    },
  },
  '@media (max-width: 1024px)': {
    container: {
      padding: '150px 24px 80px 24px',
    },
    artistsList: {
      gap: '2rem',
    },
    artists: {
      width: '100%',
    },
    artistsSection: {
      padding: '4rem 1rem',
    },
  },
});

export default useStyles;
