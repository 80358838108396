import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    padding: '0 24px 80px 24px',
    minHeight: '100vh',
  },

  speakersMaxWidth: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    maxWidth: '1280px',
  },

  speakersList: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginBottom: '2rem',
  },

  speakers: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    padding: '18px 18px 40px 18px',
    width: '33.33%',
    color: 'var(--green)',
    '& img': {
      width: '100%',
      objectFit: 'cover',
    },
    '& img.big': {
      height: '4.2cm',
      padding: 0,
    },
  },

  imageContainer: {
    width: '100%',
    paddingBottom: '100%',
    position: 'relative',
  },

  image: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },

  speakersTitle: {
    fontFamily: 'Switzer',
    fontSize: 32,
    fontWeight: 600,
    fontStyle: 'normal',
    letterSpacing: 0,
    textAlign: 'center',
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    padding: '1rem 0',
    '& span': {
      fontSize: '16px',
    },
  },

  speakersAbout: {
    fontFamily: 'Switzer',
    fontSize: 16,
    fontWeight: 400,
    fontStyle: 'normal',
    letterSpacing: 0.4,
    textAlign: 'center',
  },

  speakerTitle: {
    fontFamily: 'Switzer',
    fontSize: 'calc(24px + 1vw)',
    fontWeight: '600',
    fontStyle: 'normal',
    letterSpacing: 0,
    textAlign: 'center',
    color: 'var(--red)',
  },

  detailButton: {
    marginTop: '10px',
    padding: '10px 20px',
    backgroundColor: '#007BFF',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#0056b3',
    },
  },
  '@media (max-width: 1024px)': {
    speakersList: {
      gap: '2rem',
    },
    speakers: {
      width: "100%"
    },
    speakersSection: {
      padding: '4rem 1rem',
    },
  },
});

export default useStyles;
